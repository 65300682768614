<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Stock
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Stocks"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="stocks"
          :search.sync="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isDescending"
          :footer-props="{
            showFirstLastPage: true,
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="StockForm">
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.materialStockInvoiceId"
                            :items="invoices"
                            item-text="reference"
                            item-value="materialStockInvoiceId"
                            label="Invoice"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.materialId"
                            :items="materials"
                            item-text="materialName"
                            item-value="materialId"
                            label="Material"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.locationId"
                            :items="locations"
                            item-text="locationName"
                            item-value="locationId"
                            label="Location"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.cost"
                            label="Cost"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.quantity"
                            label="Quantity"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.invoice`]="{ item }">
            <div
              v-if="
                invoices != null &&
                invoices.length > 0 &&
                item.materialStockInvoiceId
              "
            >
              {{
                invoices.find(
                  (x) =>
                    x.materialStockInvoiceId === item.materialStockInvoiceId
                ).reference
              }}
            </div>
          </template>
          <template v-slot:[`item.material`]="{ item }">
            <div
              v-if="
                materials != null && materials.length > 0 && item.materialId
              "
            >
              {{
                materials.find((x) => x.materialId === item.materialId)
                  .materialName
              }}
            </div>
          </template>
          <template v-slot:[`item.location`]="{ item }">
            <div
              v-if="
                locations != null && locations.length > 0 && item.locationId
              "
            >
              {{
                locations.find((x) => x.locationId === item.locationId)
                  .locationName
              }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Invoice",
        align: "start",
        value: "invoice",
      },
      {
        text: "Material",
        value: "material",
      },
      {
        text: "Location",
        value: "location",
      },
      {
        text: "Cost",
        value: "cost",
      },
      { text: "Quantity", value: "quantity" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    sortBy: "assetStockId",
    isDescending: true,
    stocks: [],
    materials: [],
    invoices: [],
    locations: [],
    editedIndex: -1,
    editedItem: {
      materialStockInvoiceId: 0,
      materialId: 0,
      locationId: 0,
      cost: "",
      quantity: "",
    },
    defaultItem: {
      materialStockInvoiceId: 0,
      materialId: 0,
      locationId: 0,
      cost: "",
      quantity: "",
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Stock" : "Edit Stock";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("/MaterialStock").then((response) => {
        this.stocks = response.data;
      });
      this.$http.get("/MaterialStockInvoice").then((response) => {
        this.invoices = response.data;
      });
      this.$http.get("/Material").then((response) => {
        this.materials = response.data;
      });
      this.$http.get("/Location").then((response) => {
        this.locations = response.data.data;
      });
    },

    editItem(item) {
      this.editedIndex = this.stocks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.stocks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let materialIndex = this.editedIndex;
      this.$http
        .post("/MaterialStock/Trash", {
          materialStockId: this.editedItem.materialStockId,
        })
        .then((response) => {
          if (response.data.success) {
            this.stocks.splice(materialIndex, 1);
            this.$toast.success("Invoice deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(
              "Something went wrong while deleting the invoice.",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.StockForm.validate()) {
        let materialIndex = this.editedIndex;
        if (materialIndex > -1) {
          this.$http
            .patch("/MaterialStock/Update", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.invoices.splice(materialIndex, 1, response.data.data);
                this.$toast.success(
                  "Invoice Updated successfully.",
                  "Success",
                  {
                    position: "topRight",
                  }
                );
              } else {
                this.$toast.error(
                  "Something went wrong while updating the invoice.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        } else {
          this.$http
            .post("/MaterialStock/Create", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.stocks.unshift(response.data.data);
                this.$toast.success("Stock added successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(
                  "Something went wrong while adding stock.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        }
        this.close();
      }
    },
  },
};
</script>
